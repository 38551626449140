import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Alert, Button, Col, Input, Label, Row } from 'reactstrap';
import { getImageTypeFromBase64, removeMimeTypeFromBase64 } from '../../../helpers/image.js';
import DefaultLoader from '../../../layout/Loader/DefaultLoader';


export default class ImageShrink extends Component 
{
    constructor(props) {
        super(props)
        this.state = {
            imageObj: { id: "", content: "", width: "", height: "", ext: "" , rawData : ""},
            loadingInProgress: false,
            isImageTypeSupported: true,
            imgSrc : '',
            isImgHidden: false,
            image_data : '',
			maxImgHeight: this.getWidthAndHeightByDeviceType().height,
			maxImgWidth: this.getWidthAndHeightByDeviceType().width

        }
    }

    componentDidMount()
    {
        let image = this.state.imageObj;
        image.id = this.props.id;

        if(this.props.imageContent){
            image.content   = this.props.imageContent.content;
            image.width     = this.props.imageContent.width;
            image.height    = this.props.imageContent.height;
            image.ext       = this.props.imageContent.ext;
            this.populateCanvas(this.props.imageContent.rawData);
            //this.populateCanvas(`data:image/${this.state.imageObj.ext};base64,${this.state.imageObj.content}`);
        }
        else {
            image.content = image.width = image.height = image.ext = "";
        }
        
        this.setState({
            imageObj: image,
            loadingInProgress: false,
            isImageTypeSupported: true,
			maxImgHeight: this.getWidthAndHeightByDeviceType().height,
			maxImgWidth: this.getWidthAndHeightByDeviceType().width
        });
    }

    componentDidUpdate(prevProps) 
    {
        if(this.props.imageContent !== prevProps.imageContent)
        {
            let image = this.state.imageObj;
            image.id = this.props.id;
            if(this.props.imageContent)
            {
                image.content   = this.props.imageContent.content;
                image.width     = this.props.imageContent.width;
                image.height    = this.props.imageContent.height;
                image.ext       = this.props.imageContent.ext;
                this.populateCanvas(this.props.imageContent.rawData);
            }
            else {
                image.content = image.width = image.height = image.ext = "";
            }
            
            this.setState({
                imageObj: image,
                loadingInProgress: false
            });
        }

		if(prevProps.deviceType !== this.props.deviceType) {
			this.setState({
				maxImgHeight: this.getWidthAndHeightByDeviceType().height,
				maxImgWidth: this.getWidthAndHeightByDeviceType().width
			})
		}
    }

	getWidthAndHeightByDeviceType = () => {
		const width = this.props.deviceType == "1" ? 576 : 384;
		const height = this.props.deviceType == "1" ? 400 : 400;
		return {width: width, height: height}
	}

    populateCanvas = (image_data) => {

        if(image_data === '') return;

        this.canvas = document.getElementById(this.props.idcanvas);
        let context = this.canvas.getContext('2d', { willReadFrequently: true });
        this.canvas.width = this.props.imageContent.width;
        this.canvas.height = this.props.imageContent.height;

        let rawLength, byteArray;

        if(typeof image_data == 'string'){

            let decodedData = atob(image_data);
            rawLength = decodedData.length;

            byteArray = new Uint8Array(new ArrayBuffer(rawLength));
            
            for(let i = 0; i < rawLength; i++){
    
                byteArray[i] = decodedData.charCodeAt(i);
                
            }

        }
        else{

            byteArray = image_data;
            rawLength = byteArray.length;

        }

        let canvas_data = context.getImageData(0,0,this.canvas.width,this.canvas.height);
        let pixels = canvas_data.data;

        let j = 0;
        for(let i = 0; i < rawLength; i++){

            let bits = byteArray[i].toString(2).padStart(8,'0');
            
            for(let k = 0; k < bits.length; k++){

                pixels[j+1] = pixels[j+2] = pixels[j] = (bits.charAt(k) == 0 ? 0 : 255);
                pixels[j+3] = 255;

                j+=4;

            }

        }

        let imageData = new ImageData(pixels, this.props.imageContent.width, this.props.imageContent.height);

        context.putImageData(imageData,0,0);

        let rawData = this.setRawDataState();

        let imageObj = this.state.imageObj;
        imageObj.rawData = rawData;

        this.setState({
            imageObj: {
                ...this.state.imageObj,
                rawData : rawData
            }
        }, () => {

            this.props.setImage(imageObj);

        });

    }

    removeImageHandler = (event) => {
        event.preventDefault();

        let image = this.state.imageObj;
        image.content = image.width = image.height = image.ext = image.rawData = "";

        this.setState({
            imageObj: image,
            loadingInProgress: false
        }, () => this.props.setImage(this.state.imageObj));


        let canvas = document.getElementById(this.props.idcanvas);
        let context = canvas.getContext('2d');
        
        this.deleteCanvas(context, canvas);

    }

    isImageMimeTypeSupported = (mimeType) => {
        let supportedMimeTypes = ['image/png', 'image/bmp', 'image/jpeg'];
        let idx = supportedMimeTypes.indexOf(mimeType)
        if(idx !== -1){
            return true;

        }
        return false;
    }

    handleImageChange = async (event) => {

        event.preventDefault();
        var file = event.target.files[0];
        if(typeof file == "undefined") return ;

        /* let base64Img       = await getBase64(file);
        let base64MimeType  = getImageTypeFromBase64(base64Img);  */     

        this.handleCanvas(event.target);//,base64MimeType);
        

        return;
    }

    deleteCanvas = (context, canvas) => {
        context.save();

        // Use the identity matrix while clearing the canvas
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Restore the transform
        context.restore();
    }

    handleCanvas = (input,mimeType) => {
        // imageObj: { id: "", content: "", width: "", height: "", ext: "" },
        this.setState({
            isImgHidden: false,
            loadingInProgress : true,
            imgSrc: ''
        });

        let reader = new FileReader();

        reader.onload = e => {

            let mimeType = getImageTypeFromBase64(reader.result);

            this.canvas = document.getElementById(this.props.idcanvas);
            let context = this.canvas.getContext('2d', { willReadFrequently: true });
            
            this.deleteCanvas(context, this.canvas);

            let img = new Image();
            img.src = e.target.result;

            img.onload = async () => {

                this.resizeImage(img,this.canvas);

                //let image_element = document.getElementById(this.props.idimg);

                context.drawImage(img,0,0,this.canvas.width,this.canvas.height);

                this.setState({
                    isImgHidden: true
                });

                let image_data = context.getImageData(0,0,this.canvas.width,this.canvas.height);

				let gs_image_data = this.transformToGrayScale(image_data);

				let new_image_data = new ImageData(gs_image_data.pixels, gs_image_data.width,gs_image_data.height);

				//If transformToGrayScale returned false it was already in b/w and dither is not needed
				new_image_data = this.ditherImage(new_image_data,mimeType,!gs_image_data.greyscale);

				//center image
				if(this.canvas.width < this.state.maxImgWidth)    
					new_image_data = this.centerImage(new_image_data,this.canvas);

				new_image_data = this.stripImageTopBottomSides(new_image_data);  

                this.setState({
                    image_data : new_image_data.data
                });

				this.canvas.height = new_image_data.height;
				this.canvas.width = new_image_data.width;

				context.putImageData(new_image_data,0,0);

                let data = this.canvas.toDataURL('bmp');

                let rawData = this.setRawDataState();

                this.setState({
                    loadingInProgress : false,
                    imageObj : {
                        ...this.state.imageObj,
                        content : removeMimeTypeFromBase64(data),
                        width : this.canvas.width,
                        height : this.canvas.height,
                        rawData : rawData
                    }
                }, () => this.props.setImage(this.state.imageObj));

            }

        };

        reader.readAsDataURL(input.files[0]);

    }

    resizeImage = (img,canvas) => {

        if(img.width > this.state.maxImgWidth || img.height > this.state.maxImgHeight){

            let diff_w = img.width - this.state.maxImgWidth;
            let diff_h = img.height - this.state.maxImgHeight;

            if(diff_w > diff_h) {

                canvas.width = this.state.maxImgWidth;
                canvas.height = ( this.state.maxImgWidth * img.height ) / img.width;

            }
            else{

                canvas.height = this.state.maxImgHeight;
                canvas.width = ( this.state.maxImgHeight * img.width ) / img.height;

            }    

        }
        else{
            canvas.height = img.height;
            canvas.width = img.width;
        }

    }

    centerImage = (image_data, canvas) => {

        let start_time = Date.now();

        let current_width = canvas.width;        
        let pixels = image_data.data;

        let rows = [];
        let n_rows = canvas.height;
        let i = 0;

        //Retrieve image rows

        while(i < n_rows){

            rows[i] = pixels.slice(
                i * current_width*4,
                (i * current_width * 4) + current_width * 4
            );

            i++;

        }

        //Calculate the amount of pixels to add on the left/ right
        let pixels_to_add = this.state.maxImgWidth - current_width;  
        let right_pixels_to_add = Math.ceil(pixels_to_add / 2);
        let left_pixels_to_add = pixels_to_add - right_pixels_to_add;
        let concat_array = [];

        let total = 0;
        
        for(let j = 0; j< rows.length; j++){

            let left_pixel_inserted = 0;
            let right_pixel_inserted = 0;
            
            rows[j] = Array.from(rows[j]); // (time 0.04 out of 3.1 total)

            //if(j === 0){
                let i = 0;
                while( i < (left_pixels_to_add * 4)){

                    rows[j].splice(0,0,255);
                    i++;

                }
            //}

            i = 0;
            while(i < (right_pixels_to_add * 4)){

                rows[j].splice(rows[j].length + i, 0 ,255);
                /*if(j !== (rows.length - 1) && left_pixel_inserted < left_pixels_to_add){
                    left_pixel_inserted++;
                    rows[j].splice(rows[j].length + i + 1, 0 ,255);
                }*/

                i++;

            }
            

            for (let e of rows[j]) concat_array.push(e);  // (time 0.02 out of 3.1)
            

        }

        this.canvas.width = this.state.maxImgWidth;

        let data = new Uint8ClampedArray(concat_array);

        return new ImageData(data,this.canvas.width,this.canvas.height);

    }

    transformToGrayScale = (image_data) => {

        let pixels = image_data.data;
        var gs_count = 0;

        for(let i = 0; i < pixels.length; i += 4){

          if(pixels[i] == pixels[i+1] && pixels[i+1] == pixels[i+2]){
            gs_count+=4;    
          }
        	
          let grayscale_color = parseInt((0.3 * pixels[i]) + (0.59 * pixels[i+1]) + (0.11 * pixels[i+2]),10);
          
          pixels[i] = pixels[i+1] = pixels[i+2] = grayscale_color;
          pixels[i+3] = 255;
          
        }

        return {
            pixels  : pixels,
            width   : image_data.width,
            height  : image_data.height,
            greyscale : gs_count === pixels.length
        }

    }

    ditherImage = (image_data,mimeType, boolDither) => {

        let pixels = image_data.data;

        let w = image_data.width;
        let h = image_data.height;

        let rawData = new Uint8Array((image_data.width * image_data.height) / 8 );
        
        let tmpBuffer = '';
        let rawDataIndex = 0;

        let pixels_tuple = [];
        let k = 0;

        for(let i=0; i < pixels.length; i +=4) {


            pixels_tuple[k] = [
                pixels[i],pixels[i+1],pixels[i+2],pixels[i+3]
            ];

            k++;

            if(tmpBuffer.length === 8){

                rawData[rawDataIndex] = parseInt(tmpBuffer,2);
                tmpBuffer = '';
                rawDataIndex++;
                
            }

            let color;
                
            if(pixels[i] > 140 || pixels[i+3] === 0) {
                color = 255;
                tmpBuffer += '1';
            }
            else{
                color = 0;
                tmpBuffer += '0';
            }

            if(boolDither){

                let err = parseInt((pixels[i] - color) / 8,10);
          
                pixels[i + 4] += err;
                pixels[i + 8] += err;
                pixels[i + (w * 4) - 4] += err;
                pixels[i + (w * 4)] += err;
                pixels[i + (w * 4) + 4] += err;
                pixels[i + (w * 8)] += err;
                pixels[i+3] = 255;
                

            } 


            pixels[i+1] = pixels[i+2] = pixels[i] = color;
          
        }

        return new ImageData(pixels,w,h);

    }

    stripImageTopBottomSides = (image_data) => {

        let pixels = image_data.data;
        let width  = image_data.width;
        let height = image_data.height;

        let rows = [];

        let removedRows = 0;
        let new_array = [];
        let j = 0;

        //Retrieve Rows
        while(j < height){

            rows[j] = pixels.slice(
                j * width * 4,
                (j * width * 4) + width * 4
            );

            j++;

        }

        //Strip Top Rows
        for(let k = 0; k < rows.length; k++) {

            for(var i = 0; i < rows[k].length; i+=4){

                if(rows[k][i] === 0) break;

            }

            if(i === (rows[k].length)) {
                rows[k] = [];
                removedRows++;
            }
            else break;

        }

        //Strip Bottom Rows
        for(let k = (rows.length - 1); k >= 0; k--) {

            for(var i = 0; i < rows[k].length; i+=4){

                if(rows[k][i] === 0) break;

            }

            if(i === (rows[k].length)) {
                rows[k] = [];
                removedRows++;
            }
            else break;

        }

        for(let row of rows){

            for(let element of row) new_array.push(element);

        }

        let data = new Uint8ClampedArray(new_array);

        return new ImageData(
            data,
            width,
            height - removedRows
        );

    }

    removeRow = (pixels,index, width) => {

        pixels.splice(
            (index * 4 * width),
            (index * 4 * width) + (width * 4) - 4
        );

        return pixels;

    }

    setRawDataState = () => {

        this.canvas = document.getElementById(this.props.idcanvas);
        let context = this.canvas.getContext('2d', { willReadFrequently: true });

        let canvas_data = context.getImageData(0,0,this.canvas.width,this.canvas.height);
        let pixels = canvas_data.data;

        let rawData = new Uint8Array((this.canvas.width * this.canvas.height) / 8 );
    
        let tmpBuffer = '';
        let rawDataIndex = 0;

        for(let i = 0; i <= pixels.length; i+=4){

            if(tmpBuffer.length === 8){

                rawData[rawDataIndex] = parseInt(tmpBuffer,2);
                tmpBuffer = '';
                rawDataIndex++;
                
            }
            if(pixels[i] === 0) {
                tmpBuffer += '0';
            }
            else {
                tmpBuffer += '1';
            }

        }

        return rawData;

    }

    render() {
		let fileInput = <Input type="file" name={this.props.inputName} id={this.props.inputName} onChange={this.handleImageChange} />
		if(this.props.imgInputPlaceholder) {
			fileInput = <>
				<Input className="d-none" type="file" name={this.props.inputName} id={this.props.inputName} onChange={this.handleImageChange} />
				<Label className="btn btn-outline-primary" for={this.props.inputName}>Seleziona file</Label>
			</>
		}
        return (
            <React.Fragment>
                <span>{this.props.labelName}</span>
				<hr />
				<Alert className="mt-3" color="primary">Il formato consigliato per le immagini da caricare è di {this.state.maxImgWidth} x {this.state.maxImgHeight} pixel</Alert>
                <hr />
                <div className="image-shrink-wrapper">
                    <Row form>
                        <Col xs={12}>
                        {  !this.state.isImageTypeSupported ? <Alert color="danger">File non supportato, sono accettate immagini nel formato png, bmp e jpeg</Alert> : "" }
                        </Col>
                    </Row>
                    <Row form className="mb-3">
                        <Col md={8} xs={8}>
                            { 
                                !this.props.isEnabledForEdits ? 
                                '' :
                                fileInput
                            }
                        </Col>
                        <Col md={4} xs={4}>
                        {this.state.imageObj.content === "" || !this.props.isEnabledForEdits ? "" : <Button color="danger" onClick={this.removeImageHandler} className="float-right"><FontAwesomeIcon icon={faTrash} /></Button> }
                        </Col>
                    </Row>
                </div>
                <div className="image-preview" >
                    <div>{this.state.loadingInProgress ? 
                    <Row>
                        <Col>
                            <DefaultLoader />
                        </Col>
                    </Row> : "" }</div>
                    <canvas id={this.props.idcanvas} style={{maxWidth: '100%', marginLeft:'auto',marginRight:'auto',display:'block'}}></canvas>
                </div>
            </React.Fragment>
                      
        )
    }
}
