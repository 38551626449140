import request from "../helpers/request.js";
import localStorage from "../helpers/localStorage.js";
import qs from "querystring";

const host = process.env.REACT_APP_API_RT_SERVICE_HOST;
const endpoint = process.env.REACT_APP_RT_CONFIGS_ENDPOINT;

/**
 * Get a list of configurations
 * configuration list is paginated and optionally it could be sorted and filtered by configuration parameters
 * @param {*} page
 * @param {*} limit
 * @param {*} search_params
 * @param {*} order_params
 */
export const getAll = async (page, limit, search_params, order_params, hideLinked) => {
	let url =
		`${endpoint}?page=${page}&limit=${limit}&sort_by=${order_params.sort}&order_by=${order_params.name}` +
		`${search_params.code === "" ? "" : "&code=" + search_params.code}` +
		`${search_params.device_type === "" ? "" : "&device_type=" + search_params.device_type}` +
		`${search_params.activity_name === "" ? "" : "&activity_name=" + search_params.activity_name}` +
		`${search_params.business_name === "" ? "" : "&business_name=" + search_params.business_name}` +
		`${search_params.vat_code === "" ? "" : "&vat_code=" + search_params.vat_code}` +
		`${search_params.zip_code === "" ? "" : "&zip_code=" + search_params.zip_code}` +
		`${search_params.address === "" ? "" : "&address=" + search_params.address}` +
		`${hideLinked === true ? "&hide_linked" : ""}`;

	return request(host, {
		method: "GET",
		url: url,
		config: {
			headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
			withCredentials: true
		}
	})
		.then((response) => response)
		.catch((err) => {
			throw err;
		});
};

/**
 * Deletes a configuration by its id
 * @param {*} id configuration's database identifier
 */
export const deleteById = async (id) => {
	return request(host, {
		method: "DELETE",
		url: `${endpoint}/${id}`,
		config: {
			headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
			withCredentials: true
		}
	})
		.then((response) => response)
		.catch((err) => {
			throw err;
		});
};

/**
 * Get a configuration by its id
 * @param {*} id configuration's database identifier
 */
export const getById = async (id) => {
	return request(host, {
		method: "GET",
		url: `${endpoint}/${id}`,
		config: {
			headers: {
				Authorization: `Bearer ${localStorage.getAccessToken()}`
			}
		}
	})
		.then((response) => response)
		.catch((err) => {
			throw err;
		});
};

export const setIsFavourite = (configurationId, isFavourite) => {
	const setIsFavouriteUrl = `${host}${endpoint}/${configurationId}`;

	return new Promise((resolve, reject) => {
		if (typeof configurationId === "undefined" || typeof isFavourite === "undefined") reject();

		let body = {};

		if (isFavourite !== null) body["is_favorite"] = isFavourite ? 1 : 0;

		request(host, {
			method: "PATCH",
			url: setIsFavouriteUrl,
			body: qs.stringify(body),
			config: {
				headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
				withCredentials: true
			}
		})
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getFavouriteConfig = async () => {
	let url = `${endpoint}/favorite`;

	return request(host, {
		method: "GET",
		url: url,
		config: {
			headers: { Authorization: `Bearer ${localStorage.getAccessToken()}` },
			withCredentials: true
		}
	})
		.then((response) => response)
		.catch((err) => {
			throw err;
		});
};
