import { faCircleNotch, faInfoCircle, faMapMarkedAlt, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import {
	Button,
	Col,
	Modal,
	ModalBody,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';

import * as DeviceAPI from '../../../services/devices';

import FormDirtyContext from "../../../contexts/FormDirtyContext.js";
import deviceHelper from '../../../helpers/device.js';
import EmbeddedGoogleMap from '../../Common/Maps/EmbeddedGoogleMap.jsx';
import GenericConfirmModal from '../../Configuration/Modal/GenericConfirmModal.jsx';
import "./rtModal.css";
import TabConfiguration from './TabConfiguration';
import TabDetails from './TabDetails';

export default class RtModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            rtDetails : {},
            isLoading: true,
            activeTab: '1',
            isUpdateInProgress: false,
            genericConfirmOpen: false,
			isFormDirty: false,
        }


    }
    componentDidMount() {

        this.getDeviceDetails();

    }

	componentWillUnmount() {

        this.setState({
            activeTab : 1
        })

    }

	setDirty = (value) => {
		if(value !== null && value !== undefined) {
			this.setState({
				isFormDirty: value
			})
		}
	}
	

    getDeviceDetails = async () => {

        const response = await DeviceAPI.get( this.props.id );

        this.setState({
            rtDetails: response.data,
            isLoading: false
        }); 
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {   
            this.setState({
                activeTab: tab
            });
        }
    }

    handleUpdateRT = async ( ) => {
        
        this.setState({
            isUpdateInProgress: true
        });

        await DeviceAPI.updateDevice( this.props.id, !this.state.rtDetails.isEnabledRemoteUpgrade ? 1 : 0 );

        await this.getDeviceDetails();

        this.setState({
            isUpdateInProgress: false
        });

    }

    toggleGenericConfirmModal = () => {
		console.log("this.state.isFormDirty", this.state.isFormDirty);
		if(this.state.isFormDirty) {
			this.setState((prevState) => (
				{
				...prevState,  
				genericConfirmOpen: !prevState.genericConfirmOpen
			}))
		}
		else {
			this.props.toggleRtModal();
		}
    }

    genericConfirmModalConfirm = () => {
        this.toggleGenericConfirmModal();
        this.props.toggleRtModal();
    }

    render() {
		const modello =  deviceHelper.fromFiscalNumberToModel(this.state.rtDetails.fiscal_number);

        return (
				<FormDirtyContext.Provider value={{isFormDirty: this.state.isFormDirty, setDirty: this.setDirty}}>
					<React.Fragment>
						<Modal id="edit-devices-modal" isOpen={this.props.isOpenRtModal} size="xl">
							<ModalHeader toggle={this.toggleGenericConfirmModal}>
								{modello} - {this.state.rtDetails.fiscal_number}
							</ModalHeader>
							<ModalBody>
								{ this.state.isLoading ? 
									<FontAwesomeIcon icon={faCircleNotch} spin size="3x"/> :
									<React.Fragment>

										<Nav tabs>

											<NavItem>
												<NavLink
													className={this.state.activeTab === '1' ? 'active' : null}
													onClick={() => { this.toggle('1'); }}
												>
													<FontAwesomeIcon icon={faInfoCircle} /> 
													<span className={this.state.activeTab === '1' ? '' : 'd-none'}> Dettagli Dispositivo</span>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink
													className={this.state.activeTab === '2' ? 'active' : null}
													onClick={() => { this.toggle('2'); }}
												>
													<FontAwesomeIcon icon={faSlidersH} /> 
													<span className={this.state.activeTab === '2' ? '' : 'd-none'}> Modello</span>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink
													className={this.state.activeTab === '3' ? 'active' : null}
													onClick={() => { this.toggle('3'); }}
												>
													<FontAwesomeIcon icon={faMapMarkedAlt} /> 
													<span className={this.state.activeTab === '3' ? '' : 'd-none'}> Mappa</span>
												</NavLink>
											</NavItem>
											</Nav>
											<TabContent activeTab={this.state.activeTab}>

											<TabPane tabId="1">

												<TabDetails 
													fiscal_number={this.state.rtDetails.fiscal_number}
													serial_number={this.state.rtDetails.serial_number}
													created_at={this.state.rtDetails.created_at}
													isEnabledRemoteUpgrade={this.state.rtDetails.isEnabledRemoteUpgrade}
													handleUpdate={this.handleUpdateRT}
													isUpdateInProgress={this.state.isUpdateInProgress}
													device={this.state.rtDetails}
												/>
											</TabPane>

											<TabPane tabId="2">

												{this.state.activeTab === '2' ? 
													<React.Fragment>
														<br />
														<div id="rtModalBody" className="col-md-12">
															<div className="card text-left">
																<div id="" className="card-body">
																	<TabConfiguration
																		idDevice={this.props.id}
																		idConfig={this.state.rtDetails.idConfiguration}
																		fiscalNumber={this.state.rtDetails.fiscal_number}
																		toTableList={this.props.toggleRtModal}
																	/> 
																</div>
															</div>
														</div>
														
													</React.Fragment>
												: null }

											</TabPane>

											<TabPane tabId="3">
												<EmbeddedGoogleMap address={this.props.address} zip_code={this.props.zip_code} />
											</TabPane>

										</TabContent>
										<hr/>
										<Row>
											<Col>
												{/* <Button
													disabled
													color="danger"
												>
													<FontAwesomeIcon icon={faTrash} />
													{" "} Elimina
												</Button> */}
												<Button
													style={{float:'right'}}
													color='success'
													onClick={this.toggleGenericConfirmModal}
												>
													Esci
												</Button>
											</Col>
										</Row>

									</React.Fragment>
								}
							</ModalBody>
						</Modal>
						<GenericConfirmModal 
							isModalOpen={this.state.genericConfirmOpen}
							msg={'Perderai le modifiche non salvate, continuare?'}
							onConfirm={this.genericConfirmModalConfirm}
							onDismiss={this.toggleGenericConfirmModal}
							/> 
					</React.Fragment>
				</FormDirtyContext.Provider>
        )
    }
}
