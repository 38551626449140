import { faAlignCenter, faAlignLeft, faCaretDown, faCaretUp, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardTitle, Col, Collapse, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import ImageShrink from '../../Common/ImageShrink/ImageShrink';
import "./receiptFormData.css";

const receipt_head_section_name = "head";
const receipt_footer_section_name = "footer";
const receipt_collapse_logo_panel = "logo";
const receipt_collapse_receipt_panel = "receipt";

export default class ReceiptFormData extends Component {

    
    constructor(props) {
        super(props)

    
        this.state = {
            isFormValid: true,
            images : [
                { id: 1, height: "", width: "", content: "", ext: "",rawData: ""},
                { id: 2, height: "", width: "", content: "", ext: "",rawData: ""}
            ],
            header_section : [
                {id: 1, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 2, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 3, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 4, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 5, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 6, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 7, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 8, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 9, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 10, value:'', type:'normal', valid: true, feedback: "", align: "left"}
            ],
            footer_section : [
                {id: 1, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 2, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 3, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 4, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 5, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 6, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 7, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 8, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 9, value:'', type:'normal', valid: true, feedback: "", align: "left"},
                {id: 10, value:'', type:'normal', valid: true, feedback: "", align: "left"}
            ],
            collapse : {
                logo: true,
                receipt: true
            },
            receipt_form_data_feedback: "Ogni riga deve contenere al massimo 48 caratteri",
            receipt_item_max_length: 48,
            device_type: props.deviceType ?? "1", //default Basiq
			showAlertMissingVatCode: false
        }
    }

    componentDidMount(){
        if(typeof this.props.data == 'undefined') {
            this.isFormValid()
            return ;
        }

        let images = [
            { id: 1, height: "", width: "", content: "", ext: "",rawData: ""},
            { id: 2, height: "", width: "", content: "", ext: "",rawData: ""},
        ]

        let device_type = this.props.deviceType;
		const head = this.props.data.header_section ?  this.props.data.header_section : this.state.header_section;
		const foot = this.props.data.footer_section ?  this.props.data.footer_section : this.state.footer_section;
		const photos  = this.props.data.images ? this.props.data.images : this.state.images;


        this.setState(prevState => ({
            header_section: head.map((header_row) => {
                if(!header_row.align){
                    if(this.checkSpaces(header_row.value)){
                        return {...header_row, value: header_row.value.trim(), align: 'center'}
                    }else{
                        return {...header_row, align: 'left'}
                    }
                }else {
                    if(header_row.align === 'center'){
                        return {...header_row, value: header_row.value.trim()}
                    }
                }
                return header_row
            }),
            footer_section: foot.map((footer_row) => {
                if(!footer_row.align){
                    if(this.checkSpaces(footer_row.value)){
                        return {...footer_row,  value: footer_row.value.trim(), align: 'center'}
                    }else{
                        return {...footer_row, align: 'left'}
                    }
                }else {
                    if(footer_row.align === 'center'){
                        return {...footer_row, value: footer_row.value.trim()}
                    }
                }

                return footer_row

            }),
            images: images.map((img) => {
                const imageFound = photos.find((img_prop) => img_prop.id === img.id)
                if(imageFound){
                    return {...imageFound}
                }else{
                    return img
                }
            }),
            
            // this.props.data.images,
            

            device_type: device_type,
            receipt_form_data_feedback: device_type === "2" ? "Ogni riga deve contenere al massimo 32 caratteri": "Ogni riga deve contenere al massimo 48 caratteri",
            receipt_item_max_length: device_type === "2" ? 32 : 48,
        }), () => {
            this.isFormValid()
        });
    }
    componentDidUpdate(prevProps){
        if(prevProps.data === undefined && this.props.data !== undefined){
            let images = [
                { id: 1, height: "", width: "", content: "", ext: "",rawData: ""},
                { id: 2, height: "", width: "", content: "", ext: "",rawData: ""}
            ]
            
            this.setState(prevState => ({
                header_section: this.state.header_section.map((header_row) => {
                    if(!header_row.align){
                        if(this.checkSpaces(header_row.value)){
                            return {...header_row, value: header_row.value.trim(), align: 'center'}
                        }else{
                            return {...header_row, align: 'left'}
                        }
                    }else {
                        if(header_row.align === 'center'){
                            return {...header_row, value: header_row.value.trim()}
                        }
                    }
                    return header_row
                }),
                footer_section: this.state.footer_section.map((footer_row) => {
                    if(!footer_row.align){
                        if(this.checkSpaces(footer_row.value)){
                            return {...footer_row,  value: footer_row.value.trim(), align: 'center'}
                        }else{
                            return {...footer_row, align: 'left'}
                        }
                    }else {
                        if(footer_row.align === 'center'){
                            return {...footer_row, value: footer_row.value.trim()}
                        }
                    }
                    return footer_row
                }),
                images: images.map((img) => {
                    const imageFound = this.props.data.images.find((img_prop) => img_prop.id === img.id)
                    if(imageFound){
                        return {...imageFound}
                    }else{
                        return img
                    }
                })
            }), () => {
                this.isFormValid()
            });
        }

        if(prevProps.deviceType !== this.props.deviceType){
            this.setState({
                device_type: this.props.deviceType,
                receipt_form_data_feedback: this.props.deviceType === "2" ? "Ogni riga deve contenere al massimo 32 caratteri": "Ogni riga deve contenere al massimo 48 caratteri",
                receipt_item_max_length: this.props.deviceType === "2" ? 32 : 48
            }, () => {
                this.isFormValid()
            });
        }

    }


    checkSpaces = (string) => {
        if(string.length > 2 && string.charAt(0) === ' ' && string.charAt(string.length - 1) === ' ')
            return true

        return false    
            
    }

    setImage = (imageObject) => {

        const images = this.state.images; 
        
        images[imageObject.id - 1].height     = imageObject.height;
        images[imageObject.id - 1].width      = imageObject.width;
        images[imageObject.id - 1].content    = imageObject.content;
        images[imageObject.id - 1].ext        = imageObject.ext;
        images[imageObject.id - 1].rawData    = imageObject.rawData;
        
        this.setState({
            images : images
        }, () => {
            this.isFormValid()
        });
    }

    handleHeaderRowTextInputChange = (id, value) => {
        let headerSection = this.state.header_section;
        
        if(value.length > this.state.receipt_item_max_length){
            headerSection[id - 1].valid = false;
            headerSection[id - 1].feedback = this.state.receipt_form_data_feedback;
        }
        else {
            headerSection[id - 1].valid = true;
            headerSection[id - 1].feedback = "";
        }
        headerSection[id - 1].value = value;
        this.setState({
            header_section: headerSection
        }, () => {
            this.isFormValid()
        });
    }

    handleHeaderRowTypeInputChange = (id, event) => {
        let rowType = "normal";
        if( event.target.checked ) {
            rowType = "double"
        }
        let headerSection = this.state.header_section;
        headerSection[id - 1].type = rowType;
        this.setState({
            header_section: headerSection
        }, () => {
            this.isFormValid()
        });
    }

    handleFooterRowTextInputChange = (id, value) => {
        let footerSection = this.state.footer_section;
        
        if(value.length > this.state.receipt_item_max_length){
            footerSection[id - 1].valid = false;
            footerSection[id - 1].feedback = this.state.receipt_form_data_feedback;
        }
        else {
            footerSection[id - 1].valid = true;
            footerSection[id - 1].feedback = "";
        }
        footerSection[id - 1].value = value;
        this.setState({
            footer_section: footerSection
        }, () => {
            this.isFormValid()
        });
    }

    handleFooterRowTypeInputChange = (id, event) => {
        let rowType = "normal";
        if( event.target.checked){
            rowType = "double"
        }
        let footerSection = this.state.footer_section;
        footerSection[id - 1].type = rowType;
        this.setState({
            footer_section: footerSection
        }, () => {
            this.isFormValid()
        });
    }

    /**
     * Copies informations from first step of wizard to first rows of receipt header
     */
    handleCopyFromCustomerData = () => {
        let header_data = this.state.header_section;
        let customerDataArray = [this.props.activity_name, this.props.business_name, this.props.vat_code, this.props.fiscal_code, this.props.address, this.props.zip_code];
        let customerDataArrayLength = customerDataArray.length;
        let i;

        for(i=0; i < customerDataArrayLength; i++)
        {
            let item                = customerDataArray[i];
            let isInputValid        = item.length <= this.state.receipt_item_max_length;
            header_data[i].value    = item;
            header_data[i].valid    = isInputValid; 
            header_data[i].feedback = isInputValid ? "" : this.state.receipt_form_data_feedback;
        }
        
        this.setState({
            header_section: header_data
        }, () => {
            this.isFormValid()
        });
    }

    handleLeftAlignmentRowData = (sectionName) => {
        if(sectionName === receipt_head_section_name){
            this.setState({
                header_section: this.state.header_section.map((header_row) => ({...header_row, align: 'left'}))
            }, () => {
				this.isFormValid()
			})
        }else{
            this.setState({
                footer_section: this.state.footer_section.map((footer_row) => ({...footer_row, align: 'left'}))
            }, () => {
				this.isFormValid()
			})
        }
    }

    /** Centers receipt rows */
    handleCenterRowData = (sectionName) => {
        if(sectionName === receipt_head_section_name){
            this.setState({
                header_section: this.state.header_section.map((header_row) => ({...header_row, align: 'center'}))
            }, () => {
				this.isFormValid()
			})
        }else{
            this.setState({
                footer_section: this.state.footer_section.map((footer_row) => ({...footer_row, align: 'center'}))
            }, () => {
				this.isFormValid()
			})
        }
    }

    selectLogoType = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.isFormValid()
        });
    }

    isFormValid = () => {
		this.setState({showAlertMissingVatCode: false});
        let stepValidationStatus = this.checkReceiptFormValidity();
        
        this.props.isStepValidated(stepValidationStatus, this.props.tabId);
        
        if(stepValidationStatus) this.saveData();
    }

    checkReceiptFormValidity = () => {
        const headerSection = this.state.header_section;
        const footerSection = this.state.footer_section;
		const checkVat = this.props.deviceType === "2" ? true : false;
		let hasVatCode = false;

        let headerValidationStatus = headerSection.every((headerItem) => {
			if(checkVat && new RegExp(`\\b${this.props.vat_code}\\b`).test(headerItem.value)) {
				hasVatCode = true;
			}

            return headerItem.valid ? true : false;
        });

        let footerValidationStatus = footerSection.every((footerItem) => {
            return footerItem.valid ? true : false
        })

		// Override if no vat code found 
		if(checkVat && !hasVatCode) {
			headerValidationStatus = false;
			this.setState({showAlertMissingVatCode: true});
		}
        return headerValidationStatus && footerValidationStatus ;
    }

    toggleCollapse = (sectionName) => {
        let collapseState = this.state.collapse;
        collapseState[sectionName] = !collapseState[sectionName];

        this.setState({
            collapse: collapseState
        });
    }

    saveData = () => {
        this.props.saveReceiptFormData({
            header_section : this.state.header_section,
            footer_section : this.state.footer_section,
            images : this.state.images,
        });
    }

    renderReceiptHeaderRows = ({id, value, type, valid, feedback, align}) => (
        <React.Fragment key={id}>
            {/* <Col xs={2} style={{textAlign: 'center'}}>{id}</Col> */}
            <Col xs={9}>
                <Input type="text" className="monospace-font" style={{textAlign: align === 'center' ? 'center' :'left' }} id={`head_row_desc_${id}`} name={`head_row_desc_${id}`} 
                    onChange={e => this.handleHeaderRowTextInputChange(id, e.target.value)} 
                    invalid={!valid}
                    value={value}/>
                <FormFeedback>{feedback}</FormFeedback>
            </Col>
            <Col xs={3} style={{minHeight: 20, textAlign: 'center'}}>
                <FormGroup check>
                    <Input id={`head_row_type_${id}`}  name={`head_row_type_${id}`} type="checkbox" onChange={ e => this.handleHeaderRowTypeInputChange(id, e)} checked={type === "double" ? true : false }/>
                </FormGroup>
            </Col>
        </React.Fragment>
    )

    renderReceiptFooterRows = ({id, value, type, valid, feedback, align }) => (
        <React.Fragment key={id}>
            {/* <Col xs={2} style={{textAlign: 'center'}}>{id}</Col> */}
            <Col xs={9}>
                <Input type="text" className="monospace-font" style={{textAlign: align === 'center' ? 'center' :'left'}} id={`footer_row_desc_${id}`} name={`footer_row_desc_${id}`} 
                    onChange={e => this.handleFooterRowTextInputChange(id, e.target.value)} 
                    invalid={!valid}
                    value={value}/>
                <FormFeedback>{feedback}</FormFeedback>
            </Col>
            <Col xs={3} style={{minHeight: 20, textAlign: 'center'}}>
                <FormGroup check>
                    <Input id={`footer_row_type_${id}`} name={`footer_row_type_${id}`} type="checkbox" onChange={ e => this.handleFooterRowTypeInputChange(id, e)} checked={type === "double" ? true : false }/>
                </FormGroup>
            </Col>
        </React.Fragment>
    )
        
    render() {
        return (
            <React.Fragment>
                <div id="logoCard" className="card">
                    <div className="card-header" style={{cursor: "pointer"}} onClick={e => this.toggleCollapse(receipt_collapse_logo_panel)}>
                        <span>Intestazione <FontAwesomeIcon className="float-right" icon={this.state.collapse.logo ? faCaretUp : faCaretDown} /></span>
                    </div>
                    <Collapse isOpen={this.state.collapse.logo}>
                        <div className="card-body">
                            <Row className="mb-3">
							<Col lg={6} xl={6}>
                                    <Card className="overflow-auto">
                                        <CardBody>
                                            <CardTitle>Intestazione Scontrino</CardTitle>
                                            <hr />
											{
											this.state.showAlertMissingVatCode && 
												<Row className="mb-4">
													<Col md={12}>
														<Alert className="mt-3" color="danger">Inserire la Partita Iva nell'Intestazione Scontrino</Alert>
													</Col>
												</Row>
											}
                                            <Row className="mb-4">
                                                <Col>
                                                    <Button className="mtb-5" outline color="primary" onClick={this.handleCopyFromCustomerData}><FontAwesomeIcon icon={faCopy}/> Copia da intestazione</Button>
                                                    {' '}
                                                    <Button className="mtb-5" outline color="primary" onClick={e => this.handleLeftAlignmentRowData(receipt_head_section_name)}>
                                                        <FontAwesomeIcon icon={faAlignLeft} /> Allinea a sinistra
                                                    </Button>
                                                    {' '}
                                                    <Button className="mtb-5" outline color="primary" onClick={e => this.handleCenterRowData(receipt_head_section_name)}>
                                                        <FontAwesomeIcon icon={faAlignCenter} /> Centra
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row form style={{alignItems: 'center'}}>
                                                {/* <Col xs={2} style={{textAlign: 'center'}}>Riga</Col> */}
                                                <Col xs={9}>Contenuto</Col>
                                                <Col xs={3}>Doppia Altezza</Col>
                                                { this.state.header_section.map(this.renderReceiptHeaderRows,this) }
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6} xl={6}>
                                    <Card>
                                        <CardBody>
                                            <ImageShrink 
                                                key={1}
                                                id={1}
                                                labelName={"Logo Intestazione"}
                                                inputName={"image_1"}
                                                imageContent={this.state.images[0]}
                                                imageShrinkHost={process.env.REACT_APP_API_RT_SERVICE_HOST}
                                                imageShrinkMethod={"POST"}
                                                imageShrinkUrl={process.env.REACT_APP_RT_IMAGE_SHRINK_ENDPOINT}
                                                setImage={this.setImage } 
                                                idimg = {"uploaded-img-1"} 
                                                idcanvas = {"preview1"}
                                                isEnabledForEdits = {this.props.isEnabledForEdits}
												deviceType={this.props.deviceType}
												imgInputPlaceholder
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>                        
                </div>                    
                <br />
                <div id="receiptCard" className="card">
                    <div className="card-header" style={{cursor: "pointer"}} onClick={e => this.toggleCollapse(receipt_collapse_receipt_panel)}>
                        <span>Cortesia <FontAwesomeIcon className="float-right" icon={this.state.collapse.receipt ? faCaretUp : faCaretDown} /></span>
                    </div>
                    <Collapse isOpen={this.state.collapse.receipt}>
                        <div className="card-body">
                            <Row className="mb-4">
							<Col lg={6} xl={6}>
                                    <Card className="overflow-auto">
                                        <CardBody>
                                            <CardTitle>Footer messaggio scontrino</CardTitle>
                                            <hr />
                                            <Row className="mb-4">
                                                    <Col>
                                                        <Button className="mtb-5" outline color="primary" onClick={e => this.handleLeftAlignmentRowData(receipt_footer_section_name)}>
                                                            <FontAwesomeIcon icon={faAlignLeft} /> Allinea a sinistra
                                                        </Button>
                                                        {' '}
                                                        <Button className="mtb-5" outline color="primary" onClick={e => this.handleCenterRowData(receipt_footer_section_name)}>
                                                            <FontAwesomeIcon icon={faAlignCenter} /> Centra
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row form style={{alignItems: 'center'}}>
                                                    {/* <Col xs={2} style={{textAlign: 'center'}}>Riga</Col> */}
                                                    <Col xs={9}>Contenuto</Col>
                                                    <Col xs={3}>Doppia Altezza</Col>
                                                    { this.state.footer_section.map(this.renderReceiptFooterRows,this) }
                                                </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6} xl={6}>
                                    <Card>
                                        <CardBody>
                                            <ImageShrink 
                                                key={2}
                                                id={2}
                                                labelName={"Logo Cortesia"}
                                                inputName={"image_2"}
                                                imageContent={this.state.images[1]}
                                                imageShrinkHost={process.env.REACT_APP_API_RT_SERVICE_HOST}
                                                imageShrinkMethod={"POST"}
                                                imageShrinkUrl={process.env.REACT_APP_RT_IMAGE_SHRINK_ENDPOINT}
                                                setImage={this.setImage } 
                                                idimg = {"uploaded-img-2"}
                                                idcanvas = {"preview2"} 
                                                isEnabledForEdits = {this.props.isEnabledForEdits}
												deviceType={this.props.deviceType}
												imgInputPlaceholder
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <hr></hr>             
                            </Row> 
                        </div>
                    </Collapse>
                </div>
            </React.Fragment>
        )
    }
}