import { faExternalLinkAlt, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
//import deptsStartingData from './data/depts';
import AtecoCodesModal from './AtecoCodesModal';
import Dept from './Items/Dept';

const DEFAULT_ATECO_CODES_STATE = [
    { id : 0, value : '' },
    { id : 1, value : ''},
    { id : 2, value : ''}
];
const MAX_DEPTS_BASIQ = 10;
const MAX_DEPTS_SMART = 40;

export default class DeptFormData extends Component {
    constructor(props){
        super(props);

        this.state = {
            currentDeptRows : 6,
            maxDeptRows : props.deviceType === "2" ? MAX_DEPTS_SMART : MAX_DEPTS_BASIQ,
            minDeptRows : 1,
            validation_status : {},
			isAddButtonDisabled: false,
            dept : [
                { id: 1, tasto_reparto: 1, description: 'Reparto 1', id_tax: -1, exemption_reason: 0, limit_price:0, price: 0, no_sconto: true, ventilation: false, tasto_reparto_valid: true, valid: true, limit_price_valid: true, price_valid: true, tax_valid: false, ateco_code : "0", type : "0", sell_mode: "0"},
                { id: 2, tasto_reparto: 2, description: 'Reparto 2', id_tax: -1, exemption_reason: 0, limit_price:0, price: 0, no_sconto: true, ventilation: false, tasto_reparto_valid: true, valid: true, limit_price_valid: true, price_valid: true, tax_valid: false, ateco_code : "0", type : "0", sell_mode: "0"},
                { id: 3, tasto_reparto: 3, description: 'Reparto 3', id_tax: -1, exemption_reason: 0, limit_price:0, price: 0, no_sconto: true, ventilation: false, tasto_reparto_valid: true, valid: true, limit_price_valid: true, price_valid: true, tax_valid: false, ateco_code : "0", type : "0", sell_mode: "0" },
                { id: 4, tasto_reparto: 4, description: 'Reparto 4', id_tax: -1, exemption_reason: 0, limit_price:0, price: 0, no_sconto: true, ventilation: false, tasto_reparto_valid: true, valid: true, limit_price_valid: true, price_valid: true, tax_valid: false, ateco_code : "0", type : "0", sell_mode: "0" },
                { id: 5, tasto_reparto: 5, description: 'Reparto 5', id_tax: -1, exemption_reason: 0, limit_price:0, price: 0, no_sconto: true, ventilation: false, tasto_reparto_valid: true, valid: true, limit_price_valid: true, price_valid: true, tax_valid: false, ateco_code : "0", type : "0", sell_mode: "0" },
                { id: 6, tasto_reparto: 6, description: 'Reparto 6', id_tax: -1, exemption_reason: 0, limit_price:0, price: 0, no_sconto: true, ventilation: false, tasto_reparto_valid: true, valid: true, limit_price_valid: true, price_valid: true, tax_valid: false, ateco_code : "0", type : "0", sell_mode: "0" }
            ],
            isValidForm: false,
            isFormLocked: false,
            atecoCodes : DEFAULT_ATECO_CODES_STATE,
            isOpenAtecoCodesModal : false,
            departments: props.deviceType === "2" ? [{value: 1, disabled: false},{value: 2, disabled: false},{value: 3, disabled: false},{value: 4, disabled: false},{value: 5, disabled: false},{value: 6, disabled: false},{value: 7, disabled: false},{value: 8, disabled: false},{value: 9, disabled: false},{value: 10, disabled: false},{value: 11, disabled: false},{value: 12, disabled: false},{value: 13, disabled: false},{value: 14, disabled: false},{value: 15, disabled: false},{value: 16, disabled: false},{value: 17, disabled: false},{value: 18, disabled: false},{value: 19, disabled: false},{value: 20, disabled: false},{value: 21, disabled: false},{value: 22, disabled: false},{value: 23, disabled: false},{value: 24, disabled: false},{value: 25, disabled: false},{value: 26, disabled: false},{value: 27, disabled: false},{value: 28, disabled: false},{value: 29, disabled: false},{value: 30, disabled: false},{value: 31, disabled: false},{value: 32, disabled: false},{value: 33, disabled: false},{value: 34, disabled: false},{value: 35, disabled: false},{value: 36, disabled: false},{value: 37, disabled: false},{value: 38, disabled: false},{value: 39, disabled: false},{value: 40, disabled: false}] : [{value: 1, disabled: false},{value: 2, disabled: false},{value: 3, disabled: false},{value: 4, disabled: false},{value: 5, disabled: false},{value: 6, disabled: false},{value: 7, disabled: false},{value: 8, disabled: false},{value: 9, disabled: false},{value: 10, disabled: false}]
        }
    }


	initDepts() {
		let dept = this.props.data?.length > 0 ? this.props.data : this.state.dept;
		let newStates = this.state.departments;
		dept.forEach(d => {
			let found = this.state.departments.findIndex( item => item.value === d.tasto_reparto);
			if(found !== -1) {
				newStates[found].disabled = true;
			}
		});
		this.setState({
			departments: newStates,
			dept: dept
		});

	}

    componentDidMount(){

		this.initDepts();

        if(typeof this.props.data == 'undefined') return ;

        let data = this.decodeData();

        this.setState({
            dept : data,
            currentDeptRows : data.length,
            atecoCodes: this.props.atecoCodes || this.state.atecoCodes
        },() => {
            this.checkValidForm()
        });
    }

    componentDidUpdate(prevProps) {
		if(prevProps.deviceType !== this.props.deviceType) {
			// If device type is changed
			this.setState({
				maxDeptRows: this.props.deviceType === "2" ? MAX_DEPTS_SMART : MAX_DEPTS_BASIQ,
				// isAddButtonDisabled: !this.validateDeviceTypeDeptsCount(),
				isAddButtonDisabled: this.disableAddBtnByType(this.state.dept),
			}, () => {
				this.checkValidForm()
			});
		}

        if(prevProps.data === undefined && this.props.mod === 'edit' && this.props.data !== undefined){

            let data = this.decodeData();

            const atecoCodes = (this.props.atecoCodes && this.props.atecoCodes.length > 0 ) 
                ? this.props.atecoCodes
                : DEFAULT_ATECO_CODES_STATE;

            this.setState({
                dept : data,
                currentDeptRows : data.length,
                atecoCodes: atecoCodes
            }, () => {
                this.checkValidForm()
            });

        }

    }

    handleAtecoCodesChange = ( index, value ) => {

        let atecoCodes = [...this.state.atecoCodes];

        atecoCodes[index].value = value;

        this.setState({
            atecoCodes: atecoCodes
        });

    }

    toggleAtecoCodeModal = () => {

        this.setState({
            isOpenAtecoCodesModal: !this.state.isOpenAtecoCodesModal
        })

    }

    decodeData() {
        if(this.props.mod === 'edit'){

            let dept_array = [];

            const departmentsInitialState =  [...this.state.departments]; 

            this.props.data.forEach( (dept) => {

                let obj = {
                    id : dept.id,
                    tasto_reparto: dept.tasto_reparto ?? dept.id,
                    description : dept.description,
                    id_tax: dept.id_tax,
                    exemption_reason: dept.exemption_reason,
                    limit_price: dept.limit_price ?? 0,
                    price: dept.price,
                    no_sconto: dept.no_sconto ?? true,
                    ventilation: dept.ventilation,
                    limit_price_valid : true,
                    price_valid : true,
                    tasto_reparto_valid: true,
                    valid: true,
                    tax_valid: true,
                    ateco_code: dept.ateco_code || "0",
                    type: dept.type || "0"
                };

                //this.handleDescriptionChange(dept.id, atob(dept.description));

                dept_array.push(obj);

                departmentsInitialState[obj.tasto_reparto - 1].disabled = true;

            });

            this.setState({
                departments: departmentsInitialState
            })

            return dept_array;
        }
        else {
            return this.props.data;
        }

    }

    handleAddNewDeptRow = () => {

        if(this.state.dept.length === this.state.maxDeptRows - 1){
            this.setState({
                isAddButtonDisabled: true
            })
        }

        const currentDeps = this.state.departments;
        const firstFreeDepartment = currentDeps.find(dep => !dep.disabled);
        currentDeps[firstFreeDepartment.value -1].disabled = true;
        let deptState = this.state.dept;
        let newDeptRow = {
            id: this.state.currentDeptRows + 1,
            tasto_reparto: firstFreeDepartment.value,
            description: '',
            id_tax: -1,
            exemption_reason: 0,
            limit_price: 0,
            price: 0,
            no_sconto: true,
            ventilation: false,
            feedback: "",
            limit_price_valid : true,
            price_valid: true,
            tax_valid: false,
            tasto_reparto_valid: true
        }

        deptState.push(newDeptRow);

        this.setState({
            currentDeptRows : this.state.currentDeptRows + 1,
            dept: deptState,
            isValidForm: false,
            departments: currentDeps
        }, () => {
            this.checkValidForm()
        });

    }

    handleRemoveDeptRow = (id, reparto) => {

        let deptState = this.state.dept;
        
        const filteredDeptState = deptState.filter(dept => dept.tasto_reparto !== reparto);
        const currentDeps = this.state.departments;
        currentDeps[reparto -1].disabled = false;


        this.setState({
            currentDeptRows : this.state.currentDeptRows - 1,
            dept: filteredDeptState,
            isValidForm: !this.validateDeviceTypeDeptsCount(),
            isAddButtonDisabled: this.disableAddBtnByType(filteredDeptState),
            departments: currentDeps
        }, () => {
            this.checkValidForm()
        });
    }

	disableAddBtnByType = (depts) => {
		const type = this.props.deviceType;
		if((type === "1" && depts.length >= MAX_DEPTS_BASIQ) || (type === "2" && depts.length >= MAX_DEPTS_SMART)) {
			return true;
		}
		else {
			return false;
		}
	}

    handleTastoRepartoChange = (index, prevValue, tasto_reparto) => {
        let deptState = this.state.dept;
		const deptIndex = deptState.findIndex(item => item.tasto_reparto === prevValue);

        const departmentsCurrentState =  [...this.state.departments]; 
        departmentsCurrentState[+prevValue - 1].disabled = false;
        departmentsCurrentState[+tasto_reparto - 1].disabled = true;

        // const deptWithsameTastoReparto = this.state?.dept?.filter((compareDept) => index != compareDept.id && tasto_reparto == compareDept.tasto_reparto) ?? [];
        
        if(!isNaN(tasto_reparto) && tasto_reparto > 0 ){
            deptState[deptIndex].tasto_reparto_valid = true;
        }
        else{
            deptState[deptIndex].tasto_reparto_valid = false;
        }

        deptState[deptIndex].tasto_reparto = tasto_reparto;
        
        this.setState({
            dept: deptState,
            departments: departmentsCurrentState
        }, () => this.checkValidForm());
    }

    handleDescriptionChange = (index, reparto, description) => {

        let deptState = this.state.dept;
		const deptIndex = deptState.findIndex(item => item.tasto_reparto === reparto);
        deptState[deptIndex].description = description;

        if(description.length === 0){
            deptState[deptIndex].valid = false;
            deptState[deptIndex].feedback = "La descrizione è obbligatoria";
        }
        else if(description.length > 20){
            deptState[deptIndex].valid = false;
            deptState[deptIndex].feedback = "La descrizione deve essere lunga al massimo 20 caratteri";
        }
        else {
            deptState[deptIndex].valid = true;
            deptState[deptIndex].feedback = "";
        }

        this.setState({
            dept : deptState
        }, () => this.checkValidForm())
    }

    handleDeptTaxChange = (index, reparto, id_tax) => {

        let deptState = this.state.dept;
		const deptIndex = deptState.findIndex(item => item.tasto_reparto === reparto);

        deptState[deptIndex].id_tax = id_tax;

        if(id_tax !== 0 && id_tax !== ""){
            deptState[deptIndex].exemption_reason = 0;
        }

        if(id_tax < 0){
            deptState[deptIndex].tax_valid = false;
        } else {
            deptState[deptIndex].tax_valid = true;
        }

        this.setState({
            dept: deptState
        }, () => this.checkValidForm());

    }

    handleExemptionVatChange = (index, reparto, exemption_reason) => {
        let deptState = this.state.dept;

		const deptIndex = deptState.findIndex(item => item.tasto_reparto === reparto);
        deptState[deptIndex].exemption_reason = exemption_reason;

        this.setState({
            dept: deptState
        }, () => this.checkValidForm());
    }

    handleLimitPrice = (index, reparto, limit_price) => {
        let deptState = this.state.dept;
		const deptIndex = deptState.findIndex(item => item.tasto_reparto === reparto);
        
        if(!isNaN(limit_price) && limit_price >= 0 ){
            deptState[deptIndex].limit_price_valid = true;

        }
        else{
            deptState[deptIndex].limit_price_valid = false;
        }
		limit_price = limit_price.replace(",", ".");
        deptState[deptIndex].limit_price = limit_price;
        
        this.setState({
            dept: deptState
        }, () => this.checkValidForm());
    }

    handleDefaultPrice = (index, reparto, price) => {
        let deptState = this.state.dept;
		const deptIndex = deptState.findIndex(item => item.tasto_reparto === reparto);
        
        if(!isNaN(price) && price >= 0 && price < 10000 ){
            deptState[deptIndex].price_valid = true;

        }
        else{
            deptState[deptIndex].price_valid = false;
        }

		price = price.replace(",", ".");
        deptState[deptIndex].price = price;
        
        this.setState({
            dept: deptState
        }, () => this.checkValidForm());
    }

    handleNoScontoChange = (index, reparto, value) => {
        let depts = [...this.state.dept];

        depts.forEach( d => {
            if( d.tasto_reparto === reparto) d.no_sconto = value
        });

        this.setState({
            depts: depts
        }, () => this.checkValidForm());
    }

    handleVatVentilationChange = ( index, reparto, value ) => {
		
        let depts = [...this.state.dept];

        depts.forEach( d => {
            if( d.tasto_reparto === reparto) {
				// If dept type is "SERVIZIO" you can't enable VAT ventilation
				if(d.type === "1" && value === true) {
					alert('Operazione non consentita');
				}
				else {
					d.ventilation = value
				}
			}
        });

        this.setState({
            depts: depts
        }, () => this.checkValidForm());

    }

    handleTypeChange = ( index, reparto, value ) => {

        let depts = [...this.state.dept];

        depts.forEach( d => {
            if( d.tasto_reparto === reparto) {
				// If VET ventilation is enabled you can't change dept type to "SERVIZIO"
				if(d.ventilation === true && value === "1") {
					alert('Operazione non consentita');
				}
				else {
					d.type = value
				}
			} 
        });

        this.setState({
            depts: depts
        }, () => this.checkValidForm());

    }

    handleAtecoCodeChange = ( index, reparto, value ) => {

        let depts = [...this.state.dept];

        depts.forEach( d => {
            if( d.tasto_reparto === reparto) d.ateco_code = value
        });

        this.setState({
            depts: depts
        }, () => this.checkValidForm());

    }

    handleSellModeChange = ( index, reparto, value ) => {

        let depts = [...this.state.dept];

        depts.forEach( d => {
            if( d.tasto_reparto === reparto) d.sell_mode = value
        });

        this.setState({
            depts: depts
        }, () => this.checkValidForm());

    }

    checkValidForm = () => {

        let valid_response = this.state.dept.every( (dept) => {
            return dept.tasto_reparto_valid && dept.limit_price_valid && dept.valid && dept.price_valid && dept.tax_valid ? true : false;
        });

		// 
		if(valid_response) {
			valid_response = this.validateDeviceTypeDeptsCount();
		}

        this.props.isStepValidated(valid_response, this.props.tabId);
        this.props.saveDeptData(this.state.dept, this.state.atecoCodes);
        
    }

	validateDeviceTypeDeptsCount = () => {
		let isOk = true;
		if(this.props.deviceType == "1" && this.state.dept.length > MAX_DEPTS_BASIQ) {
			// if device is basiq, depts cannot be more than 10
			isOk = false;
		}

		if(this.props.deviceType == "2" && this.state.dept.length > MAX_DEPTS_SMART) {
			// if device is smart tde, depts cannot be more than 40
			isOk = false;
		}

		// this.setState({
		// 	isAddButtonDisabled: !isOk
		// })

		return isOk;
	}
        
    renderDeptRow = ({id, tasto_reparto, description,id_tax,exemption_reason,limit_price,price, no_sconto, ventilation,tasto_reparto_valid, valid, feedback, limit_price_valid, price_valid, tax_valid, ateco_code, type, sell_mode}) => 
        <Dept 
            key={'dept'+id+'_'+tasto_reparto} 
            id={id}
            tasto_reparto={tasto_reparto} 
            description={description} 
            id_tax={parseInt(id_tax)} 
            exemption_reason={exemption_reason}
            limit_price={limit_price}
            price={price}
            no_sconto={no_sconto}
            ventilation={ventilation}
            tasto_reparto_valid={tasto_reparto_valid}
            valid={valid}
            feedback={feedback}
            limit_price_valid={limit_price_valid}
            price_valid={price_valid}
            tax_valid={tax_valid}
            ateco_code={ateco_code}
            type={type}
            sell_mode={sell_mode}
            maxRows={this.state.maxDeptRows}
            handleSellModeChange={this.handleSellModeChange}
            handleTastoRepartoChange={this.handleTastoRepartoChange}
            handleDescriptionChange={this.handleDescriptionChange}
            handleDeptTaxChange={this.handleDeptTaxChange}
            handleExemptionVatChange={this.handleExemptionVatChange}
            handleLimitPrice={this.handleLimitPrice}
            handleDefaultPrice={this.handleDefaultPrice}
            taxes={this.props.taxes}
            atecoCodes={this.state.atecoCodes}
            handleNoScontoChange={this.handleNoScontoChange}
            handleVatVentilationChange={this.handleVatVentilationChange}
            handleAtecoCodeChange={this.handleAtecoCodeChange}
            handleTypeChange={this.handleTypeChange}
            handleRemoveDeptRow={this.handleRemoveDeptRow}
            departments={this.state.departments}
        />

    render() {
        return (
            <React.Fragment>

                <Button
                    color="primary"
                    style={{marginTop:'10px', marginBottom: '10px'}} 
                    outline
                    onClick={this.toggleAtecoCodeModal}>
                        Codici Ateco <FontAwesomeIcon icon={faExternalLinkAlt} /> 
                    </Button>

                <AtecoCodesModal
                    onAtecoCodeChange={this.handleAtecoCodesChange}
                    isOpen={this.state.isOpenAtecoCodesModal}
                    toggle={this.toggleAtecoCodeModal}
                    atecoCodes={this.state.atecoCodes} />

                <br />
                <h5>Reparti</h5>
                <hr></hr>
                    {this.state.dept.map(this.renderDeptRow,this)}
                    <Row>
                        <Col>
                            <Button
                                style={{float:'left', marginRight: '10px'}}
                                color="primary"
                                outline
                                onClick={this.handleAddNewDeptRow}
                                disabled={this.state.isAddButtonDisabled}
                            >
                                <FontAwesomeIcon icon={faPlusSquare} size="lg"/> Aggiungi Reparto
                            </Button>
                        </Col>
                    </Row>

            </React.Fragment>
        )
    }
}
